import { Fragment, useCallback, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useTranslation from 'app/hooks/useTranslation'
import Link from 'next/link';
import { LogoFour } from 'app/design/LogoFour'
import { useRouter } from 'next/router'
import { FontAwesomeIcon } from 'app/icon';
import usePerson from 'app/hooks/usePerson';
import { useLogin } from 'app/hooks/useLogin';
import Appear from 'app/components/appear';
import { useLogout } from 'app/hooks/useLogout';
import { LoggedInAs } from 'app/components/LoggedInAs';
import { Pressable, View } from 'app/native';
import FullLogo from 'app/design/FullLogo';
import { Markdown } from 'app/components/markdown';
import { Colors } from 'app/design/tailwind';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

type WebTab = {
  key: "hub" | "family" | "logout";
  onPress?: () => void;
  name: string;
  icon: any;
  href?: string;
  current: boolean;
};

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { login } = useLogin();
  const { hardLogout: logout } = useLogout();
  const { t } = useTranslation();
  const router = useRouter();
  const user = usePerson();

  const crashCount = useRef(0);
  const crashTimeout = useRef<any>(null);
  const crashCounter = useCallback(() => {
    crashCount.current = crashCount.current + 1;
    if (crashCount.current >= 5) {
      alert("Vi crasher lige app'en for at se om vi får fejlen i vores systemer. Tak, og undskyld.");
      throw new Error('Crash Counter');
    }
    clearTimeout(crashTimeout.current);
    crashTimeout.current = setTimeout(() => {
      crashCount.current = 0;
    }, 5000);
  }, []);

  let activeTab = "hub";
  if (router.asPath == `/family`) activeTab = "family";

  const navigation: WebTab[] = [
    {
      key: "hub", name: t("settings.hub"), href: `/`, icon: {
        active: (
          <div className="w-5 h-5">
            <LogoFour size="icon" fill="fill-brand-black-900" />
          </div>
        ),
        inactive: (
          <div className="w-5 h-5">
            <LogoFour size="icon" fill="fill-brand-black-500" />
          </div>
        ),
      }, current: activeTab == "hub",
    },
    {
      key: "family", name: t("settings.family"), href: `/family`, icon: {
        active: (
          <FontAwesomeIcon icon={["fas", "family"]} className="w-5 h-5 text-brand-black-900" />
        ), inactive: (
          <FontAwesomeIcon icon={["fal", "family"]} className="w-5 h-5 text-brand-black-500" />
        )
      }, current: activeTab == "family",
    }
  ]

  const bottomNavigation: WebTab[] = [
    {
      key: "logout", name: t("settings.logout"), onPress: logout, icon: {
        active: (
          <FontAwesomeIcon icon={["fal", "arrow-left-from-bracket"]} className="w-5 h-5 text-brand-black-900" />
        ), inactive: (
          <FontAwesomeIcon icon={["fal", "arrow-left-from-bracket"]} className="w-5 h-5 text-brand-black-500" />
        )
      }, current: activeTab == "logout",
    }
  ];

  return (
    <div className="h-full">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-brand-black-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <FontAwesomeIcon icon={["fal", "close"]} className="w-6 h-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 bg-gradient-to-t from-[#E5E0DC] via-[#F1F6F4] to-[#F1F6F4] px-6 pb-4 overflow-y-scroll overflow-x-visible">
                  <nav className="flex flex-1 flex-col justify-between">
                    <ul role="list" className="flex flex-col gap-y-7 mt-20">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li className="" key={item.name}>
                              <Link
                                href={item.href as string}
                                className={classNames(
                                  "relative",
                                  item.current
                                    ? ' font-t-bold text-brand-black-900'
                                    : 'text-brand-black-500',
                                  'flex gap-x-3 items-center rounded-md p-2 pl-3.5 text-sm leading-6 font-t-semibold'
                                )}
                              >
                                <>
                                  {item.current ? item.icon.active : item.icon.inactive}
                                </>
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>

                    <Pressable onPress={crashCounter} className="flex flex-col items-start">
                      <FullLogo className="" />
                      <View className="mt-2">
                        <Markdown fontSize={14} color={Colors.brand.black["500"]} text={t("settings.subtitle")} />
                      </View>
                    </Pressable>

                    <div className="">
                      <LoggedInAs />
                      <ul role="list" className="-mx-2 mt-3 space-y-1">
                        {bottomNavigation.map((item) => (
                          <li className="" key={item.name}>
                            <Link
                              href={item.href || ""}
                              onClick={item.onPress}
                              className={classNames(
                                "relative",
                                item.current
                                  ? ' font-t-bold text-brand-secondary-teal'
                                  : 'text-brand-black-500',
                                'flex items-center gap-x-3 rounded-md p-2 pl-3.5 text-sm leading-6 font-t-semibold'
                              )}
                            >
                              <>
                                {item.current ? item.icon.active : item.icon.inactive}
                              </>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="h-full flex-1">
        <main className="h-full flex-1">
          <div className="h-full flex-1">
            {children}
          </div>
        </main>
        <div className="fixed top-0 flex items-center">
          {user?.ephemeral ? (
            <Appear delay={2700}>
              <button type="button" className="p-5 flex space-x-2 flex-row items-center text-brand-black-700" onClick={login}>
                <span className="sr-only">Open sidebar</span>
                <FontAwesomeIcon icon={["fal", "user"]} className="h-5 w-5" aria-hidden="true" />
                <span className="">
                  Log ind
                </span>
              </button>
            </Appear>
          ) : (
            <button type="button" className="absolute top-0 left-0 p-5 text-brand-black-700" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon icon={["fal", "bars"]} className="h-5 w-5" aria-hidden="true" />
            </button>
          )}
        </div>
      </div >
    </div >
  )
}


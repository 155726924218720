import { View, Text } from 'app/native';
import { LogoFour } from 'app/design/LogoFour'
import { ViewProps } from 'react-native';

export default (props: ViewProps) => {
  return (
    <View {...props}>
      <View className="flex flex-row items-center justify-center space-x-2">
        <View className="w-6 h-6">
          <LogoFour size="small" fill="fill-brand-black-700" />
        </View>
        <Text className="text-xl text-brand-black-700 tracking-wider font-b-semi text-center">Oase</Text>
      </View>
    </View>
  );
}

import useMainframe from "app/hooks/useMainframe";
import { memo, useEffect } from "react";
import { Image, Text, Platform } from "react-native";
import { useQuery } from '@tanstack/react-query'
import { styled } from 'nativewind'

const StyledImage = styled(Image);

export const OgImage = memo(({ url }: { url: string }) => {
  const { getOgImage } = useMainframe();

  useEffect(() => {
    console.log('initialized og');
  }, []);

  const { data } = useQuery(
    [url],
    () => getOgImage(url),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      keepPreviousData: true,
    },
  );

  console.log(`rendering og image: ${url}: ${data?.url}`);
  return data?.url ? (
    <>
      <StyledImage
        style={{
          width: 25,
          height: 25,
          marginBottom: Platform.OS == "web" ? -7 : -6,
        }}
        className="rounded shadow overflow-hidden"
        source={{ uri: data.url }}
      />
      <Text style={{
      }}> </Text>
    </>
  ) : null;
});

import { useState, useEffect, useRef, memo, ComponentProps } from 'react';
import { TextStyle } from 'react-native';
import { AnimatePresence, MotiView } from 'moti';
import { View, Text } from 'app/native';
import { styled } from 'nativewind';

// @ts-ignore
export const Cycler = styled<ComponentProps<typeof Text> & { style?: TextStyle, strings: string[], interval?: number }>(memo(({ style, strings, interval }: { style: any, strings: string[], interval?: number }) => {
  const words = strings;

  const widthRef = useRef(0);
  const [width, setWidth] = useState(widthRef.current);
  const [currentWord, setCurrentWord] = useState(words[0]);
  const wordIndex = useRef(0);
  const intervalRef = useRef<any>();

  const updateWord = () => {
    clearInterval(intervalRef.current); // clear interval before setting a new one
    intervalRef.current = setInterval(updateWord, interval || 2000);
    wordIndex.current = (wordIndex.current + 1) % words.length;
    setCurrentWord(words[wordIndex.current]);
  };

  useEffect(() => {
    updateWord();

    return () => clearInterval(intervalRef.current); // clear interval on unmount
  }, []);

  const animateFrom = {
    opacity: 0,
    translateY: -5,
  };

  const animateTo = {
    opacity: 1,
    translateY: 0,
  };

  const animateOut = {
    opacity: 0,
    translateY: 5,
  };

  return (
    <View className="inline-flex" style={{ width }} key={words.join("")}>
      {words.map((word, i) => (
        <Text onLayout={(arg: any) => {
          const thisWidth = arg.nativeEvent.layout.width;
          if (thisWidth > widthRef.current) {
            widthRef.current = thisWidth;
            setWidth(thisWidth);
          }
          }} key={i} className="web:fixed absolute opacity-0 w-screen" style={style}>
          {word}
        </Text>
      ))}
      <AnimatePresence exitBeforeEnter>
        <MotiView
          from={animateFrom}
          animate={animateTo}
          exit={animateOut}
          transition={{
            type: 'timing',
            duration: (interval || 2000) / 10,
          }}
          key={currentWord} // change key to trigger animation
        >
          <Text style={style}>
            {currentWord || " "}
          </Text>
        </MotiView>
      </AnimatePresence>
    </View>
  );
}));


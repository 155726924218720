import { useCallback, useEffect, useMemo } from "react";
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { signIn } from 'app/lib/mainframeFetch';
import useTranslation from 'app/hooks/useTranslation';
import { useDispatch } from 'react-redux'
import { reset } from 'app/slices/projections';
import { useToken } from 'app/hooks/useToken';
import { Platform } from 'react-native';

interface TokenResult {
  id_token: string;
}

interface AuthResult {
  params?: TokenResult;
}

const PROMISE_ENDPOINT = "https://promiseauthentication.org/a/oase.app";

WebBrowser.maybeCompleteAuthSession();

export const useLogin = ({force}: {force?: boolean} = {}) => {
  const [token, setToken] = useToken();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const redirectUri = useMemo(() => {
    if(!token) return "";

    if (Platform.OS == "web") {
      return AuthSession.makeRedirectUri({
        path: `authenticate?andThen=${encodeURIComponent(encodeURIComponent(window.location.href))}`,
      });
    } else {
      return AuthSession.makeRedirectUri({
        path: `authenticate`,
      });
    }
  }, [token]);

  const loginUrl = useMemo(() => {
    let url = `${PROMISE_ENDPOINT}?redirect_uri=${redirectUri}`;
    if (force) {
      url += "&prompt=login";
    }
    return url;
  }, [redirectUri]);

  const authParams = useMemo(() => {
    const params: AuthSession.AuthRequestConfig = {
      clientId: 'oase.app',
      responseType: AuthSession.ResponseType.IdToken,
      redirectUri,
    };
    if (force) {
      params['prompt'] = AuthSession.Prompt.Login;
    }
    return params;
  }, [redirectUri, force]);

  const [_request, result, promptAsync] = AuthSession.useAuthRequest(
    authParams, {
    authorizationEndpoint: PROMISE_ENDPOINT,
  });

  const prepareUserSwitch = useCallback(async () => {
    dispatch(reset());
  }, [dispatch, reset]);

  const handleIdToken = useCallback(async (idToken: string) => {
    return signIn(idToken).then((accessToken) => {
      prepareUserSwitch();
      setToken(accessToken);
    }).catch(() => {
      alert(t('login.error'));
    });
  }, [signIn, prepareUserSwitch, setToken, t]);

  useEffect(() => {
    const res = result as AuthResult;
    const idToken = res?.params?.id_token;
    if (idToken) {
      handleIdToken(idToken);
    }
  }, [result, handleIdToken]);

  const doLogin = useCallback(() => {
    if (Platform.OS == "web") {
      window.location.href = loginUrl;
    } else {
      promptAsync({});
    }
  }, [loginUrl, promptAsync]);

  return { login: doLogin, handleIdToken };
}

import { MotiView as View } from "moti"
import { useState, memo } from "react"
import { ViewProps } from "react-native"

const Appear = (({ children, delay, ...props }: ViewProps & { children: React.ReactNode, delay?: boolean | number, className?: string }) => {
  const [to] = useState<number | number[]>(delay === true ? [0, 0, 1] : 1);
  const [from] = useState<number | number[]>(0);

  return (
    <View
      {...props}
      from={{ opacity: from }}
      animate={{ opacity: to }}
      transition={{
        type: "timing",
        duration: 500,
        delay: delay && delay !== true ? delay : 0
      }}
    >
      {children}
    </View>
  );
});

export default memo(Appear);

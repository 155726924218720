import { useQuery } from "@tanstack/react-query";
import useMainframe from 'app/hooks/useMainframe'
import { recursivelyDecrypt, useEncryption } from 'app/hooks/useEncryption'
import { useEffect, useState } from "react";

export const useMyNames = () => {
  const mainframe = useMainframe();
  const { decrypt } = useEncryption();
  const [names, setNames] = useState<string[]>([]);

  const { data } = useQuery(
    ['my_names'],
    () => {
      // alert("Fetching names");
      return mainframe.get(`v1/iam/me/names`);
    }
  );

  useEffect(() => {
    (async () => {
      if(!data?.names) return;

      const res = await recursivelyDecrypt(data?.names, decrypt)
      const justNames: string[] = res.map((n: any) => n.name);
      const uniqNames: string[] = [];
      for (const name of justNames) {
        if (!uniqNames.includes(name)) {
          uniqNames.push(name);
        }
      }
      setNames(uniqNames);
    })();
  }, [data, decrypt, setNames]);

  return names;
}

import { Cycler } from "app/features/hub/Cycler";
import { useMyNames } from "app/hooks/useMyNames";
import usePerson from "app/hooks/usePerson";
import { Pressable, Text } from "app/native";
import { useCallback } from "react";
import * as Clipboard from 'expo-clipboard';

export const LoggedInAs = () => {
  const names = useMyNames();
  const user = usePerson();

  const copyUserId = useCallback(() => {
    if(!user) return;

    Clipboard.setStringAsync(user?.id);
    alert("Dit bruger ID er kopieret til udklipsholderen.");
  }, [user]);

  return names.length > 0 ? (
    <Pressable onPress={copyUserId} key={names.join("")}>
      <Text className="text-brand-black-400 mb-1">
        Du er logget ind som:
      </Text>
      <Cycler strings={names || []} className="font-t-bold text-brand-black-700" />
    </Pressable>
  ) : null;
};

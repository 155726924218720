import { useDispatch } from 'react-redux'
import { reset } from 'app/slices/projections';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useCallback, useEffect, useContext } from 'react';
import { logout } from 'app/lib/mainframeFetch';
import { useToken } from 'app/hooks/useToken';
import { useTracking } from './useTracking';
import EncryptionKeysContext, { EncryptionKeysRepo } from 'app/contexts/encryptionKeys'

WebBrowser.maybeCompleteAuthSession();

export const useLogout = () => {
  const keyBank = useContext<EncryptionKeysRepo>(EncryptionKeysContext);
  const dispatch = useDispatch();
  const [_token, setToken] = useToken();
  const tracking = useTracking();

  const logoutRedirectUri = AuthSession.makeRedirectUri({
    path: '',
  });

  const [_logoutRequest, logoutResult, logoutPromptAsync] = AuthSession.useAuthRequest({
    clientId: 'oase.app',
    responseType: AuthSession.ResponseType.IdToken,
    redirectUri: logoutRedirectUri,
  }, {
    authorizationEndpoint: "https://promiseauthentication.org/logout",
  });

  const softLogout = useCallback(async () => {
    await logout()
    keyBank.reset();
    dispatch(reset());
    setToken(null);
    if(tracking?.reset) tracking.reset();
  }, [dispatch, reset, keyBank, tracking, setToken, logout]);

  useEffect(() => {
    if (logoutResult) {
      softLogout();
    }
  }, [logoutResult, softLogout]);

  return { hardLogout: logoutPromptAsync };
};
